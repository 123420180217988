<template>
  <div class="rule-index">
    <subnav currPage="footerRule" showPrevious></subnav>
    <v-container class="my-5">
      <div class="rule-card white py-4">
        <div class="rule-title font-w-4 rem-6 rem-sm-9 py-1 mb-4 mx-auto text-center primary white--text">{{ $t('footerRule') }}</div>
        <div class="font-w-4 rem-1 rem-sm-3 lightGrey1--text text-center mb-6 mb-sm-5">{{ $t('ruleWarning') }}</div>

        <div
          class="rule-content font-w-4 px-12 px-sm-11 pt-sm-0 pb-6 d-flex align-center darkGrey--text"
          v-for="(item, i) in $t('ruleContent')"
          :key="i"
        >
          {{ item }}
        </div>

        <btnPill
          class="mx-auto d-block"
          btnText="depositImmediately"
          maxWidth="236px"
          @clickBtn="$router.push({name: 'Deposit', params: {lang: $store.state.locale, interestToken: $store.state.interestTokens[0].token, depositToken: $store.state.depositTokens[0].token}})"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
import subnav from '@/components/subnav.vue'
import btnPill from '@/components/btn-pill'
export default {
  components: {
    subnav,
    btnPill
  },
}
</script>
<style lang="scss">
.rule-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
  }
  .rule-card {
    overflow: hidden;
    border-radius: 6px;
    @include dai_vuetify_sm {
      background: #FBFBFB;
      border-radius: 9px;
    }
    .rule-title {
      width: 207px;
      border-radius: 27px;
    }
  }
}
</style>